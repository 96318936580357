.App {
  text-align: center;
}

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Acme&family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo&display=swap");

document {
  font-family: "Cairo";
}
body {
  background-image: url("assets/login/Rectangle.png");
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Cairo";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

:root {
  --primaryColor: #583a95;
  --secColor: #28d4f1;
  --hoverColor: #375df7;
  --primaryRGBA: rgba(0, 0, 0, 0.3);
  --mainGrey: #f8f8fa;
  --darkGrey: #8d8989;
  --mainWhite: #ffff;
  --mainBlack: #2222;
  --mainTransition: all 0.3s ease-in-out;
  --mainSpacing: 0.15em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.centeredForm {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow-y: hidden;
}

.login-form {
  border-radius: 15px;
  padding: 50px;
}

#centeredForm {
  background-repeat: no-repeat;
  background-size: cover;
}

.login-input-password {
  border-radius: 15px !important;
  background-image: url("assets/login/Group\ 46@2x.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px !important;
}

.login-input-username {
  border-radius: 15px !important;
  background-image: url("assets/login/Group\ 47@2x.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px !important;
}

.mainButton {
  background-image: url("assets/button.png") !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border-radius: 15px !important;
  border: none !important;
  font-family: "Cairo";
}
.secButton {
  background-color: var(--secColor) !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border-radius: 15px !important;
  border: none !important;
  font-family: "Cairo";
}

.actionLogo {
  height: auto;
  cursor: pointer;
  transition: transform 0.2s;

  /* Animation */
}

.actionLogo:hover {
  transform: scale(1.15);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.filtersLogo {
  height: 50px !important;
  cursor: pointer;
  transition: transform 0.2s;
}

.filtersLogo:hover {
  transform: scale(1.15);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

@media only screen and (max-width: 600px) {
  .actionLogo {
    height: 50px;
  }
  .filtersLogo {
    height: 100px;
  }
}

.main-text {
  color: white;
  font-family: "Cairo";
  font-weight: bolder;
}

.form_send_modal_head {
  background-color: var(--primaryColor) !important;
}
.form_send_modal_body {
  background-color: var(--primaryColor) !important;
}

.form_send_modal_foot {
  background-color: var(--primaryColor) !important;
}
